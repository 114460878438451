const $ = jQuery.noConflict();
(function ($) {
    $(document).on('click', '.ViewChallenge_container .SocialMediaShareButton_container', function (e) {
        $('.SocialMediaShareButton_container.SocialMediaShareButton_button').toggleClass('SocialMediaShareButton_contractedContainer');
        $('.SocialMediaShareButton_shareIcons').toggleClass('SocialMediaShareButton_contractedShareIcons SocialMediaShareButton_expandedShareIcons');
    });

    function socialWindow(url) {
        const left = (screen.width - 570) / 2;
        const top = (screen.height - 570) / 2;
        const params = "menubar=no,toolbar=no,status=no,width=570,height=570,top=" + top + ",left=" + left;
        window.open(url, "NewWindow", params);
    }

    const pageUrl = window.location.href;
    const tweet = encodeURIComponent($("meta[property='og:description']").attr("content"));

    $(document).on("click", ".react-share__ShareButton.facebook", function () {
        let url = "https://www.facebook.com/sharer/sharer.php?u=" + pageUrl;
        socialWindow(url);
    });

    $(document).on("click", ".react-share__ShareButton.twitter", function () {
        let url = "https://twitter.com/intent/tweet?url=" + pageUrl + "&text=" + tweet;
        socialWindow(url);
    });

    $(document).on("click", ".react-share__ShareButton.linkedin", function () {
        let url = "https://www.linkedin.com/sharing/share-offsite/?url=" + pageUrl;
        socialWindow(url);
    });

    $(document).on("click", ".react-share__ShareButton.email", function () {
        window.open('mailto:?body=' + pageUrl);
    });

    $(document).on("click", ".SocialMediaShareButton_shareLinkButton", function () {
        const temp = $("<input>");
        $("body").append(temp);
        temp.val(pageUrl).select();
        document.execCommand("copy");
        temp.remove();
        $('.Toast_Toast').css('display', 'flex').delay(5000).fadeOut('slow');
    });

    $(document).on("click", '.ViewChallengeSplashInfo_showMoreCircle', function () {
        $('html,body').animate({scrollTop: $('.ViewChallenge_belowFold').offset().top}, 800);
    });

    $(document).on("click", '.Button_reject', function () {
        let popup_id = $('#' + $(this).attr("rel"));
        let programId = $(this).data('id');
        let programUrl = $(this).data('url');
        let token = document.cookie.match('(^|;)\\s*' + 'token' + '\\s*=\\s*([^;]+)')?.pop() || '';
        let url = programUrl + '/api/v3/applications/' + programId + '/cancel';
        $(popup_id).show().css('display', 'flex');
        $("#sentRejectProgram").click(function (e) {
            e.preventDefault();
            let form = $(this);
            $.ajax({
                type: "POST",
                beforeSend: function(request) {
                    request.setRequestHeader("Authorization", token);
                },
                url: url,
                success: function () {
                    location.reload();
                },
                error: function () {
                    location.reload();
                }
            });
        });
    });

    $(document).on("click", '.NotificationModal_cancelButton', function () {
        $('#popupReject').hide();
    });

    let page = 1;
    let uri = window.location.pathname;
    $(document).on("click", '#more_posts', function(e) {
        e.preventDefault();
        let offset = $('.archivePosts-list li').size();
        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            data: {
                uri: uri.substring(1),
                action: "more_post_ajax",
                offset: offset
            },
            success: function(data) {
                page++;
                $('.archivePosts-list-show-more').append(data);
            },
            error: function(data) {
                console.log(data);
            }
        });
    });
})(jQuery);
